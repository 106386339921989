import Carousel from 'components/Carousel'
import TopCarouselImage from 'components/Home/TopCarousel/TopCarouselImage'
import TopCarouselItem from 'components/Home/TopCarousel/TopCarouselItem'
import CustomPropTypes from 'lib/custom-prop-types'
import compact from 'lodash/compact'
import map from 'lodash/map'
import { useRef, useMemo } from 'react'
import styled from 'styled-components'
import { remCalc } from 'styles/mixins'

const ContentCarousel = styled(Carousel)`
  .slick-current {
    position: relative;
    z-index: 5;
  }
`

const ContentItem = styled.div`
  max-width: ${remCalc(327)};
  width: 85vw;
  margin: ${remCalc(0, 4)};
  padding-top: ${remCalc(16)};
  height: ${remCalc(628)};
`

const ArrowWrapper = styled.button`
  top: 0;
  transform: none;
  z-index: 5;
  height: 100%;

  @media (hover: hover) and (pointer: fine) {
    /* Your CSS styles for non-touch devices */
    width: calc(50% - 164px);
}

  &:before {
    content: none;
  }
`

const LeftArrow = styled(ArrowWrapper)`
  left: 0;
  cursor: url('/yp/assets/svg/arrow-cursor-left.svg'), auto;

  &.slick-disabled {
    cursor: none;
  }
`

const RightArrow = styled(ArrowWrapper)`
  right: 0;
  cursor: url('/yp/assets/svg/arrow-cursor-right.svg'), auto;

  &.slick-disabled {
    cursor: none;
  }
`

const ArrowLeft = (props) => {
  return <LeftArrow {...props}>Previous Item</LeftArrow>
}

const ArrowRight = (props) => {
  return <RightArrow {...props}>Next Item</RightArrow>
}

const TopCarouselMobile = ({ className, entityUuids }) => {
  const sliderReference = useRef(null)
  const lockVerticalScrollWhenHorizontalSwiping = (direction) => {
    const isHorizontal = direction !== 'vertical'
    if (isHorizontal) {
      // Will be released when the gesture finish even if the slide has no changed.
      document.body.style.position = 'fixed'

    }
  }

  const releaseBodyScroll = () => {
    // Will be released when the gesture finish even if the slide has no changed.
    document.body.style.position = ''
  }

  const CarouselSettingsContent = useMemo(() => {
    return {
      centerMode: true,
      infinite: true,
      speed: 300,
      slidesToShow: 3,
      variableWidth: true,
      adaptiveHeight: true,
      prevArrow: <ArrowLeft />,
      nextArrow: <ArrowRight />,
      swipeToSlide: true,
      swipeEvent: lockVerticalScrollWhenHorizontalSwiping,
    }
  }, [])

  const items = compact(
    map(entityUuids, (entityUuid, index) => {
      if (entityUuid) {
        return (
          <ContentItem key={`content-item-${index}`}>
            <TopCarouselImage entityUuid={entityUuid} isDesktop={false} />
            <TopCarouselItem entityUuid={entityUuid} />
          </ContentItem>
        )
      }
      return false
    }),
  )

  return (
    <>
      <div className={className} onTouchEnd={releaseBodyScroll}>
        <ContentCarousel config={CarouselSettingsContent} ref={sliderReference}>{items}</ContentCarousel>
      </div>
    </>
  )
}

TopCarouselMobile.propTypes = {
  entityUuids: CustomPropTypes.uuidArray.isRequired,
}

export default TopCarouselMobile
